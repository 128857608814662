import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  // {
  //   state: '/',
  //   name: 'Dashboard',
  //   type: 'link',
  //   icon: 'icon-basic-accelerator'
  // },
  {
    state: 'commandes',
    name: 'Commandes',
    type: 'link',
    icon: 'icon-basic-sheet-multiple'
  },
  {
    state: 'reglages',
    name: 'Réglages',
    type: 'link',
    icon: 'icon-basic-accelerator'
  }
];

@Injectable()
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
