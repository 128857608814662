import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './core';
import { AuthLayoutComponent } from './core';
import { AuthGuard } from './authentication/auth.guard';

export const AppRoutes: Routes = [{
  path: '',
  component: AdminLayoutComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      redirectTo: 'commandes',
      pathMatch: 'full'
    },
    {
      path: 'commandes',
      children: [
        {
          path: '',
          loadChildren: './commandes/commandes.module#CommandesModule'
        },
        {
          path: 'new',
          loadChildren: './commandes/edit/edit.module#EditModule'
        },
        {
          path: ':id',
          loadChildren: './commandes/edit/edit.module#EditModule'
        }
      ]
    },
    {
      path: 'reglages',
      children: [
        {
          path: '',
          loadChildren: './settings/settings.module#SettingsModule'
        }
      ]
    }
  ]
}, {
  path: '',
  component: AuthLayoutComponent,
  children: [{
    path: 'authentication',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  }, {
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  }]
},
  {
  path: '**',
  redirectTo: ''
  }
];