import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  heading: string;
  @Output()
  toggleSidebar = new EventEmitter<void>();
  @Output()
  openSearch = new EventEmitter<void>();
  @Output()
  toggleFullscreen = new EventEmitter<void>();

  username : any ='';

  constructor(public globals: Globals, private router: Router, private http: ApiService) { }

  logout() {
    localStorage.clear();
    this.router.navigate(['authentication/signin']);
  }
  ngOnInit() {
  }

  resetNotificationsCount(){
    this.globals.notificationsCounts = 0
  }
}
