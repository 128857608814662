import { Injectable } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';

@Injectable()
export class Globals {
    // api_url = "http://localhost:50000";
    api_url = "https://shiply.work";

    //Spinner waiting for Api Response 
    spinnerLoading = true;

    dataPassed = null;

    notifications = [];
    notificationsCounts = 0;

    progressBars= {};

    dateOptions: DatepickerOptions = {
        minYear: 1900,
        maxYear: new Date().getFullYear() + 50,
        barTitleIfEmpty: 'Click to select the year',
    };
    headerTitle = '';
}
