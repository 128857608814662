import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Globals } from './globals';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private globals: Globals) {}
  
  get(url : string, params: {}){
    return this.http.get(this.globals.api_url.concat(url), {params: params});
  }

  post(url : string, body:{}, params: {}){
    return this.http.post(this.globals.api_url.concat(url), body, {params: params});
  }

  postToS3(url : string, body:{}){
    return this.http.post(url, body, {responseType: 'text'});
  }
  
  postToS3WithProgress(url : string, body:{}){
    const req = new HttpRequest('POST', url, body, {reportProgress: true, responseType: 'text'});

    return this.http.request(req);
  }

  postWithProgress(url : string, body:{}){
    const req = new HttpRequest('POST', this.globals.api_url.concat(url), body, {reportProgress: true});
    return this.http.request(req);
  }

  put(url : string, body:{}, params: {}){
    return this.http.put(this.globals.api_url.concat(url), body, {params: params});
  }

  export(url : string, body){
    return this.http.post(this.globals.api_url.concat(url), body, {responseType: 'blob'});
  }

  delete(url : string, params: {}){
    return this.http.delete(this.globals.api_url.concat(url), {params: params});
  }
}
