import { tap } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { Globals } from "../globals";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private globals : Globals, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        this.globals.spinnerLoading = true;

        let token = localStorage.getItem('token');
        if (token) {
            const headers = new HttpHeaders({
                'accesstoken': token
            });
            request = request.clone({ headers });
        }

        return next.handle(request).pipe(tap(
            success => {
                setTimeout(() => { this.globals.spinnerLoading = false; }, 1000);
            },
            err => {
                setTimeout(() => { this.globals.spinnerLoading = false; }, 1000);
                if (err.status == 401) {
                    localStorage.clear();
                    this.router.navigate(['/authentication/signin']);
                }
            }
        ))
    }
}